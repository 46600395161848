import { EMPTY_LINE, createText, createImage, createQRCode, createBigText, createDivider } from './entries'

export const createAllegroReturnLabel = (returnMerchantNumber, inpostPackageNumber, packageContent) => () => [
  EMPTY_LINE,
  createBigText('Zwrot'),
  // createImage('https://kitchen-printer.apps.devways.pl/images/allegro-smart.jpg'),
  createDivider('-'),
  createText('Numer zwrotu Allegro:'),
  createBigText(returnMerchantNumber, 1, 1),
  packageContent && EMPTY_LINE,
  packageContent && createText(`Ref.: ${packageContent}`),
  createDivider('-'),
  createText('Numer nadania zwrotu InPost'),
  createText('przez Allegro smart!:'),
  createText(inpostPackageNumber, 1, 1),
  createQRCode(inpostPackageNumber.replaceAll(' ', ''), 30),
  EMPTY_LINE,
  EMPTY_LINE,
]
