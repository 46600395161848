import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import styles from './TileButton.module.css'

const buildLabelURL = (entriesArray) => {
  const entriesObject = entriesArray.reduce(
    (accumulator, current, index) => ({
      ...accumulator,
      [`${index}`]: current,
    }),
    {}
  )
  return encodeURI(
    `my.bluetoothprint.scheme://https://kitchen-printer.apps.devways.pl/api/json.php?object=${JSON.stringify(
      entriesObject
    )}`
  )
}

export const TileButton = ({ children, getLabelContent, link, onClick }) => {
  const history = useHistory()
  const goToURL = (url) => (window.location.href = url)

  const getGoToRouterLink = (link) => () => {
    history.push(link)
  }

  const handleClick = () => {
    if (getLabelContent) {
      const labelContent = getLabelContent()
      const href = buildLabelURL(labelContent)
      goToURL(href)
    } else if (link) {
      // const href = getGoToRouterLink(link)
      goToURL(link)
    } else if (onClick) {
      onClick()
    }
  }

  return (
    <Paper onClick={handleClick} component="span" classes={{ root: styles.container }}>
      <div className={styles.labelContent}>
        <Textfit className={styles.textFitContainer}>{children}</Textfit>
      </div>
    </Paper>
  )
}
