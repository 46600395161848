import TileButton from './../../components/TileButton'
import { createPemosCoffeeLabel, createFragileLabel } from '../../labels/stickers'
import { createDateLabel } from '../../labels/date'
import containersStyles from './../pageContainers.module.css'

export const Home = () => (
  <div>
    <div className={containersStyles.header}>Etykiety</div>
    <div className={containersStyles.tilesContainer}>
      <TileButton getLabelContent={createDateLabel}>Data dziś</TileButton>
    </div>
    <div className={containersStyles.header}>Nalepki</div>
    <div className={containersStyles.tilesContainer}>
      <TileButton getLabelContent={createPemosCoffeeLabel}>Kawa od Pema</TileButton>
      <TileButton getLabelContent={createFragileLabel}>Nie rzucać</TileButton>
    </div>
    <div className={containersStyles.header}>Inne</div>
    <div className={containersStyles.tilesContainer}>
      <TileButton link="/allegro/returns">Allegro zwroty</TileButton>
      {/* <TileButton link="/piper">Piper</TileButton>
      <TileButton link="/kitchen">Kuchnia</TileButton>
      <TileButton link="/flights-segments">Loty</TileButton> */}
    </div>
  </div>
)
