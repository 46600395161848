import { EMPTY_LINE, createBigText, createQRCode } from './entries'

const months = ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paz', 'lis', 'gru']

export const createDateLabel = () => {
  const today = new Date()
  const date = today.getDate()
  const month = months[today.getMonth()]
  const year = today.getFullYear()

  const formattedDate = `${date} ${month} ${year}`
  const qrCodeContent = `201${today.getTime()}`

  return [
    EMPTY_LINE,
    EMPTY_LINE,
    createBigText(formattedDate, 1, 1),
    createQRCode(qrCodeContent, 15),
    EMPTY_LINE,
    EMPTY_LINE,
  ]
}
