import { useEffect, useState } from 'react'
import Trello from './../../clients/trello'
import { createAllegroReturnLabel } from './../../labels/allegro'
import TileButton from './../../components/TileButton'
import ScaleLoader from 'react-spinners/ScaleLoader'
import containersStyles from './../pageContainers.module.css'

const PACKAGES_READY_FOR_RETURN_LIST_ID = '564af27e6e7e6a3965621771'

const getCustomFieldValueByFieldId = (fields, fieldId) =>
  fields.find((field) => field.idCustomField === fieldId)?.value.text

export const AllegroReturns = () => {
  const [isLoadingPackages, setIsLoadingPackages] = useState(true)
  const [returnOrders, setReturnOrders] = useState([])

  useEffect(() => {
    async function loadTrelloData() {
      const isAuthorized = await Trello.isAuthorized()

      if (isAuthorized) {
        const returnCards = await Trello.getClient()
          .getCardsOnList(PACKAGES_READY_FOR_RETURN_LIST_ID)
          .then((cards) => cards)

        const returnCardsWithCustomFields = await Promise.all(
          returnCards.map(async ({ id, name }) => {
            const customFields = await Trello.getClient().makeRequest('get', `/1/cards/${id}/customFieldItems`)

            return {
              id,
              name,
              packageContent: getCustomFieldValueByFieldId(customFields, '62fc0747936879729fb90d96'),
              inpostReturnNumber: getCustomFieldValueByFieldId(customFields, '607f472d1ea4782ba2b8707f'),
              allegroReturnNumber: getCustomFieldValueByFieldId(customFields, '6061f04ac72636612cacbbde'),
            }
          })
        )

        setIsLoadingPackages(false)
        setReturnOrders(returnCardsWithCustomFields)
      } else {
        Trello.authorize().then(loadTrelloData)
      }
    }
    loadTrelloData()
  }, [])

  const filterOnlySmartAllegroReturnCards = ({inpostReturnNumber, allegroReturnNumber}) => !!inpostReturnNumber && !!allegroReturnNumber

  return (
    <div>
      <div className={containersStyles.header}>Allero - zwroty</div>
      <div className={containersStyles.tilesContainer}>
        <TileButton link="/">Home</TileButton>
      </div>
      <div className={containersStyles.header}>Przesyłki</div>
      <div className={containersStyles.tilesContainer}>
        {isLoadingPackages && (
          <div className={containersStyles.tileAlikeSpinnerContainer}>
            <ScaleLoader color="lightskyblue" />
          </div>
        )}
        {!isLoadingPackages &&
          returnOrders.filter(filterOnlySmartAllegroReturnCards).map((card) => (
            <TileButton
              key={card.id}
              getLabelContent={createAllegroReturnLabel(card.allegroReturnNumber, card.inpostReturnNumber, card.packageContent)}
            >
              {card.packageContent || card.name}
            </TileButton>
          ))}
      </div>
    </div>
  )
}
